/* Reset some default styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Roboto', sans-serif;
    background-color: #e9f5f2;
    color: #333;
    line-height: 1.6;
}

/* Navbar Styles */
nav {
    background-color: #2a9d8f;
    color: white;
    padding: 15px 20px;
}

nav ul {
    list-style: none;
    display: flex;
    justify-content: space-around;
    margin: 0;
    padding: 0;
}

nav ul li {
    margin: 0;
}

nav ul li a {
    color: white;
    text-decoration: none;
    padding: 10px 15px;
    font-weight: bold;
}

nav ul li a:hover {
    background-color: #264653;
    border-radius: 5px;
}

/* Banner Styles */
.banner {
    background: url('https://source.unsplash.com/1600x900/?nature,travel') no-repeat center center/cover;
    color: white;
    text-align: center;
    padding: 150px 20px;
}

.banner h1 {
    font-size: 3em;
    margin-bottom: 20px;
}

.banner p {
    font-size: 1.5em;
    margin-bottom: 20px;
    font-style: italic;
}

/* Section Styles */
section {
    padding: 60px 20px;
}

h2 {
    text-align: center;
    margin-bottom: 40px;
    font-size: 2.5em;
    color: #264653;
}

.feature, .destination, .food, .experience {
    background: white;
    margin-bottom: 20px;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.feature:hover, .destination:hover, .food:hover, .experience:hover {
    transform: scale(1.05);
}

.feature img, .destination img, .food img, .experience img {
    width: 100px;
    height: 100px;
    margin-right: 20px;
    border-radius: 50%;
    object-fit: cover;
}

.feature h3, .destination h3, .food h3, .experience h3 {
    font-size: 1.8em;
    color: #2a9d8f;
    margin-bottom: 15px;
}

.feature p, .destination p, .food p, .experience p {
    flex: 1;
}

/* Download Section Styles */
.download-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.download-buttons a {
    display: inline-block;
    padding: 15px 30px;
    background-color: #2a9d8f;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
}

download-buttons a:hover {
    background-color: #264653;
}

/* Contact Section Styles */
#contact {
    text-align: center;
}

#contact p, #contact h3 {
    margin-bottom: 10px;
}

#contact a {
    color: #2a9d8f;
    text-decoration: none;
}

#contact a:hover {
    text-decoration: underline;
}

/* Footer Styles */
footer {
    background-color: #2a9d8f;
    color: white;
    text-align: center;
    padding: 20px 0;
}

footer p {
    margin: 0;
}
