body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

nav {
    background: #333;
    color: #fff;
    padding: 10px 20px;
}

nav ul {
    list-style: none;
    display: flex;
    justify-content: space-around;
    margin: 0;
    padding: 0;
}

nav ul li {
    margin: 0;
}

nav ul li a {
    color: #fff;
    text-decoration: none;
    padding: 5px 10px;
}

.banner {
    /*background: url('banner.jpg') no-repeat center center/cover;*/
    color: #fff;
    text-align: center;
    padding: 100px 20px;
}

section {
    padding: 40px 20px;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
}

.feature, .destination, .food, .experience {
    margin-bottom: 20px;
}

.download-buttons a {
    display: inline-block;
    margin: 10px;
    padding: 10px 20px;
    background: #333;
    color: #fff;
    text-decoration: none;
}

footer {
    background: #333;
    color: #fff;
    text-align: center;
    padding: 10px 0;
}
